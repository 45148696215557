<app-header></app-header>

<div class="section">
  <div class="layer col-xs-10 col-lg-10">
    <div class="row">
      <h3>MANAGEMENT TEAM</h3>
      <div class="sidebar-info">
        <h5>Tan Ooi Jin, Executive Director</h5>
        <h5>Lim Mun Shung, Executive Director</h5>
        <h5>Lim Yong Hwa, CA (M), FCCA - Chief Financial Officer</h5>
      </div>
    </div>

    <div class="row">
      <h3>BOARD OF DIRECTORS</h3>
      <div class="sidebar-info">
        <h4>TAN OOI JIN, Executive Director</h4>
        <p>Mr. Tan was first appointed to the Board on 8 March 2019 as an Independent Non-Executive
          Director and was redesignated as Executive Director on 22 February 2021. He graduated with a
          Bachelor of Laws degree (Honours) from the University of Newcastle-upon-Tyne, United Kingdom.
          In 2002, he obtained his Certificate in Legal Practice from the Legal Profession Qualifying Board, Malaysia.
        </p>
        <p>A former ASEAN Scholar, Mr. Tan started his legal career in a medium-sized firm with an
          international affiliation focusing on corporate, securities and ICT. During his tenure as a
          practitioner, he has advised on various corporate and commercial transactions especially
          cross border transactions and was involved in the listing of various companies in Malaysia as
          well as overseas including London, Hong Kong and Singapore and has been constantly consulted
          to assist public-listed companies to recover and unlock their intrinsic value so as to
          enhance shareholders value. He was involved in corporate exercises of various companies and
          is familiar with the growth pattern of many industries and how to create or unlock value in
          these sectors.
        </p>
        <p>A former ASEAN Scholar, Mr. Tan started his legal career in a medium-sized firm with an
          international affiliation focusing on corporate, securities and ICT. During his tenure as a
          practitioner, he has advised on various corporate and commercial transactions especially cross border
          transactions and was involved in the listing of various companies in Malaysia as well as overseas
          including London, Hong Kong and Singapore and has been constantly consulted to assist public-listed
          companies to recover and unlock their intrinsic value so as to enhance shareholders value. He was
          involved in corporate exercises of various companies and is familiar with the growth pattern of many
          industries and how to create or unlock value in these sectors.
        </p>
        <p>He is currently the Executive Chairman of Vortex Consolidated Berhad.
        </p>
      </div>

      <div class="sidebar-info">
        <h4>LIM MUN SHUNG, Executive Director</h4>
        <p>Mr. Melvin is a Chartered Member of both the Royal Institute of British Architects and
          Architects Registration Board United Kingdom (“UK”) since 2007. During his tenure in the UK,
          he received numerous Design Commendation Awards, including first place Sheppard Robson Design Award
          upon completing his Diploma in Architecture RIBA Part II in 2003
        </p>
        <p>Mr. Melvin graduated in Bachelor of Honours in Architecture from University of Greenwich,
          (RIBA Part I) in year 1999 where he received LEADA Award for Creative Design in use of Energy Conservation.
        </p>
        <p>Mr. Melvin started his career working with multi discipline building firms to design architect,
          working from smaller one-off residential designs to high-end commercial developments. With over
          and above twenty (20) years of experience in the architecture and corporate development sectors
          in the UK and South East Asia, he played an integral role in many iconic development projects,
          before expanding his extensive portfolios to Malaysia in 2012. Some noteworthy projects include
          master planning strategic development for 128 acres Admiral City in Melaka, 500 acres Iconic
          Sabaidee, in Laos for their master planning and architecture design in Vientiane; and amongst
          other major developers and house builders in Malaysia.
        </p>
        <p>Mr. Melvin has served Barton Willmore as their senior design associate for seven years since
          2005, with a wide range of involvement in high-end residential, mixed use and commercial
          projects on behalf of landowners, public listed companies, major developers, house builders and
          housing associations in both country and regional level. Barton Willmore,
          is one (1) of UK's leading independent Planning and Design consultancy firms with more than
          eighty (80) years of history.
        </p>
        <p>Currently, Melvin is managing his own firm MSP (Mun Shung & Partners Sdn Bhd, previously known
          as BWLIM International Sdn. Bhd.), which he founded in 2012 with counter partners from the UK,
          and has since been actively involved as Lead Designer with strategic planning and commercial entity
          projects in Malaysia for some of the renowned private developers. MSP were formed on the basis to
          design and refine focus on creating branded environments and new experiences for organisations.
        </p>
        <p>MSP has been featured in “Asia Outlook Magazine 2020”; LuxLife UK Magazine 2019; and received
          numerous awards namely LuxLife Award UK for the “KL’s Leading and most Outstanding Multidisciplinary
          Practices 2019; Asia Pacific Award 2019-2020 for “Best Hotel Architecture Award, Best Mixed Use
          Architecture Design Award; and SME Award for the “Malaysia’s Fast moving Companies, 2018.
        </p>
        <p>Between May 2017 and March 2021 previously, Mr. Melvin had also served as an Executive Director in
          CSH Alliance Berhad (Formerly known as KTG Berhad) where he led and managed the property development
          and construction businesses including the concept master planning project for the Group.
        </p>
      </div>

      <div class="sidebar-info">
        <h4>CHAN SWEE YING, Executive Director</h4>
        <p>Ms Chan Swee Ying holds a Degree in Computer Science from University Putra Malaysia.
        </p>
        <p>She has more than 25 years of working experience and a strong background in the Information Technology (IT)
          industry. She started her career with Oracle Malaysia in 1996 before moving on to DHL Asia Pacific
          IT department managing both local and regional projects in year 2005. She also assisted in developing business
          plans to secure financial support from the banking institutions for the company's growth.
        </p>
        <p>Ms Chan is also well experienced in the Human Resources field, having assumed the role of General Manager
          from 2010 to 2015 at an ICT group of companies.
        </p>
        <P>With her management skills and competency in operational efficiency and resources administration, she has
          helped the Company increased productivity while maintaining costs and expenses especially during the economy
          slowdown.
        </P>
        <P>Currently, she is also a Non-Independent and Non-Executive Director of MMAG Holdings Berhad (MMAG), a
          position which she has held since November 2017. In addition, she was also appointed as the Executive Director
          of Vortex Consolidated Berhad on 10 April 2020.
        </P>
      </div>

      <div class="sidebar-info">
        <h4>LIM PENG TONG, Independent Non-Executive Director</h4>
        <p>Mr. Lim was appointed to the Board as Independent Non-Executive Director on 14 November 2018.
          On 9 January 2019, Mr. Lim was appointed as a member of the Audit Committee as well as Chairman
          of the Nomination and Remuneration Committees.
        </p>
        <p>He is professionally qualified with a Diploma in Banking and Financial Services from
          Institute Bank-Bank Malaysia (IBBM) in 1997 and is a Certified Credit Professional (CCP)
          since 2002. He is also an Associate member with IBBM which is now known as the
          Asian Institute of Chartered Bankers (AICB). Mr. Lim retired from Malayan Banking Berhad (“Maybank”)
          after serving for 38 years. His last position with Maybank was as Regional Head of Business
          Banking of Northern Region (Penang, Kedah and Perlis), a senior position held for 8 years
          till his retirement in 2018.
        </p>
        <p>Mr. Lim is also an Independent Non-Executive Director of CSH Alliance Berhad
          (formerly known as KTG Berhad).
        </p>
      </div>

      <div class="sidebar-info">
        <h4>TAN SIEW PENG, Independent Non-Executive Director</h4>
        <p>Ms Tan was appointed to the Board as Independent Non-Executive Director on 8 March 2019.
          She is also the Chairman of the Audit Committee as well as a member of the Nomination and
          Remuneration Committees.
        </p>
        <p>She is professionally qualified from The Association of Chartered Certified Accountants (ACCA),
          United Kingdom. Ms Tan also holds a Diploma in Commerce (Financial Accounting) from
          Tunku Abdul Rahman College (now known as Tunku Abdul Rahman University College),
          Malaysia and is a member of the Malaysian Institute of Accountants (MIA).
        </p>
        <p>Ms Tan has over 25 years’ experience in the areas of financial management, treasury,
          tax planning and compliance as well as enterprise risk management. Ms Tan started her career
          with Price Waterhouse Johor Bahru in 1994 before transiting into the commercial sector where
          she has held senior positions in companies involved in the electronics, construction and
          property development activities. She is presently the Assistant Director of Finance at
          United Malayan Land Bhd (UMLand) where she is in charge of the Finance Division of UMLand’s
          townships development in the southern region.
        </p>
      </div>

      <div class="sidebar-info">
        <h4>NG KEOK CHAI, Independent Non-Executive Director</h4>
        <p>Mr. Ng Keok Chai was appointed as Independent Non-Executive Director of the Company on 27 October 2021.
          He is also a member of the Audit Committee, Nomination Committee and Remuneration Committee.
        </p>
        <p>Mr. Ng Keok Chai holds a Bachelor of Laws (Hons) from University of Wolverhampton, UK in 1996
          while he was still in service with the Sarawak Police Contingent headquarters.
          In 1998, he obtained his Certificate in Legal Practice from the Legal Profession Qualifying
          Board, Malaysia.
        </p>
        <p>His early career started when he was recruited as a Police Inspector with the Royal Malaysia
          Police in 1982. He was then posted to serve in Sarawak and he served there for 20 years until
          attaining the rank of Assistant Superintendent of Police. During his tenure in Sarawak,
          his exposure included the Criminal Investigation Department (CID), General Duty and Police
          Field Force.
        </p>
        <p>In 2003, he was transferred to Selangor Police Contingent Headquarters to serve in
          Traffic Branch. Mr Ng was promoted to Deputy Superintendent of Police in 2005 and served in
          Commercial Crimes Investigation Department, Selangor Police Contingent Headquarters.
          He was promoted to Superintendent in 2008 and assumed the position of Deputy Head of Commercial
          Crime Investigation Department, Selangor Polis Contingent Headquarters.
        </p>
        <p>After that, Mr. Ng was posted to Johor Police Contingent Headquarters as Deputy Head of
          Commercial Crimes Investigation Department in 2014. Then in the same year, he was transferred
          to Commercial Crimes Investigation Department, Royal Malaysia Police, Bukit Aman as Assistant
          Director in Forensic Accounting Investigation Division.
        </p>
        <p>He was promoted to Assistant Commissioner of Police in 2016 and his last held position was
          that of Principal Assistant Director in Forensic Accounting Investigation Division, Commercial
          Crimes Investigation Department, Royal Malaysia Police, Bukit Aman.
        </p>
        <p>Throughout his 36 years’ service with Royal Malaysia Police, he was very much involved in
          police investigations due to his legal background. He specialised in criminal investigation
          across various fields including commercial crime, general crime and forensic accounting
          investigations with ample management and special operations experience.
        </p>
        <p>At present, Mr. Ng is an Independent Non-Executive Director in CSH Alliance Berhad
          (formerly known as KTG Berhad), Hong Seng Consolidated Berhad and Green Packet Berhad in
          Malaysia.
        </p>
      </div>

      <div class="sidebar-info">
        <h4>DATO’ SERI DR. CHEN CHAW MIN, Independent Non-Executive Director</h4>
        <p>Dato’ Seri Dr. Chen was appointed as Independent Non-Executive Director of DGB
          on 21 March 2022.
        </p>
        <p>Dato’ Seri Dr. Chen graduated from Universiti Teknologi Malaysia with a Bachelor of
          Surveying (Second Upper). He obtained a Master in Business Administration from University
          of Illinois, Urbana-Champaign, USA and completed his academic achievement with a PhD in
          Finance from Universiti Putra Malaysia.
        </p>
        <p>Dato’ Seri Dr. Chen started his career with the Malaysian Civil Service in 1988 and advanced
          to many senior positions with increasing responsibilities with the federal government.
          His first posting was in Ministry of Rural Development and from 1990 onwards, he served in
          various divisions (Budget Division, Investment Division, Housing Loan Division,
          Finance Division and Fiscal and Economy Division) in the Ministry of Finance.
          On 25 April 2014, he joined Ministry of Health as Deputy Secretary General (Finance) and was
          appointed, on 4 July 2015, as Secretary General, the highest civil service position of a
          ministry. During his tenure as Secretary General of Ministry of Health and up his retirement in
          February 2021, he was actively involved in several National Policy Task Forces and Health-related
          Councils. He also played a pivotal role in spearheading the fight against Covid-19.
        </p>
        <p>He has published in international and local journals and was invited to present papers in
          international conferences and seminars. Dato’ Seri Dr. Chen has also attended several
          prestigious short courses at Harvard Business School in US and Oxford University Said School of
          Business in UK.
        </p>
        <p>He is an alumni of Razak School of Government (RSOG) where he is a regular speaker on Leadership.
          Besides, he has been appointed as an associate of Putra Business School, University Putra Malaysia
          in 2021 where he is a lecturer in corporate finance and investment analysis for MBA program.
        </p>
        <p>
          He is currently the Independent Non-Executive Chairman of Optimax Berhad and an Independent
          Non-Executive Director of MGRC Berhad. He also sits on the board of Tropicana Golf and Country
          Resort Berhad. He is a founding member of Pecca Leather Foundation as well as trustee of For
          Every Child Foundation.
        </p>
      </div>
    </div>

  </div>




</div>


<app-footer></app-footer>