import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser'; 


@Component({
  selector: 'app-group_structure',
  templateUrl: './group_structure.component.html',
  styleUrls: ['./group_structure.component.css']
})
export class GroupStructureComponent implements OnInit {

 
  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Group Structure | Ingenieur")
  }

  
}


