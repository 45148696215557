<app-header></app-header>

<div class="backgroundBW">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <div class="section1background">
        <div class="container section1ContainerPosition center">
            <div class="section1Content">
                <p style="color:white;">Ingenieur Gudang Berhad is formerly known as Dynaciate Group Berhad.</p><br class="hidden-xs"><br class="hidden-xs">
                <a style="color:white;" routerLink="/aboutus-component"><i>Read More</i></a>
            </div>
        </div>
    </div>

    <div class="section2background">
        <div class="container-fluid center">
            <div class="row section2Content">
                <h4 style="margin-bottom: 2%;">Mission</h4><br>
                <p>To move forwards “A Catalytic Gudang Approach” by actively source and assess potential property targets that are deemed investable by Ingenieur.</p>
            </div>
        </div>
    </div>

    <div class="section section3-desktop">
        <div class="container section3-desktop-content" style="text-align: center;">
            <h4 style="color:white">Vision</h4><br><br>
            <div class="heading" style="color: black;">SAFETY</div>
            <div class="Content" a href="#">Be safety & environmentally responsible.</div><br><br>
            <div class="heading" style="color: black;">QUALITY</div>
            <div class="Content" a href="#">Provide excellent quality services.</div><br><br>
            <div class="heading" style="color: black;">TIMELINESS</div>
            <div class="Content" a href="#">On time delivery of products and services.</div><br><br>
            <div class="heading" style="color: black;">EMPLOYEE</div>
            <div class="Content" a href="#">Commitment to the shared success of our employees.</div><br><br>
            <div class="heading" style="color: black;">PARTNERSHIP</div>
            <div class="Content" a href="#">Be the preferred partner to our suppliers and customers.</div><br><br>
            <div class="heading" style="color: black;">SUSTAINABILITY</div>
            <div class="Content" a href="#">Continuous development and growth of the company.</div><br><br>
        </div>
    </div>

</div>

<router-outlet></router-outlet>
<app-footer></app-footer>