import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlatformLocation } from "@angular/common";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    currBaseURL: any;

  constructor(
    private route: Router,
    private platformLocation: PlatformLocation
  ) {

    this.currBaseURL = (this.platformLocation as any).location.origin;

   }

  ngOnInit(): void {
  }

  goToLanding(){
    this.route.navigate(['landing']);
    // this.route.navigateByUrl("/landing")
    // this.route.navigate(['landing'])
    // .then(() => {
    //     window.location.reload();
    // });

    // window.location.href = this.currBaseURL + '/'

    // alert(this.currBaseURL)
    

  }


  goToContact(){
    this.route.navigate(['contactus']);
  }


  goToAboutUs(){
    this.route.navigate(['about-us']);
  }

  goToNews(){
    this.route.navigate(['news']);
  }

  goToInvestor(){
    this.route.navigate(['investor']);
  }
  
  goToOurTeam(){
    this.route.navigate(['our-team']);
  }


  goToCareer(){
    this.route.navigate(['career']);
  }

  goToGroupStructure(){
    this.route.navigate(['group_structure']);
  }

  goToCoreBusiness(){
    this.route.navigate(['core-business']);
  }
}

