<app-header></app-header>

  <div class="section">

    <!--SEC1 SMARTPHONES-->
    <div class="hidden-sm hidden-md hidden-lg">
      <div class="container col-11 pl-0 pr-0 mb-5" style="margin-top: 20%;">
        <!--New-->
        <div class="row col-12 section1ImageMobile ml-0"
          style="background-image:url('./assets/image/Component/P5-NEWS_7.png'); background-size: cover;"></div>
        <div class="row col-12 contentCenter Background ml-0">
          <p style="margin-top: 4%;">February 8th, 2023 | TheEdgeMarkets</p>
          <h4>Ingenieur Gudang to acquire interest in 164 units of retail lots at commercial complex</h4>
          <p>Ingenieur Gudang Bhd is buying a firm that has acquired the interest in 164 units of retail
            lots in a commercial complex in Pandan Perdana.</p>
          <a class="links" style="margin-top: 2%; margin-bottom:4%" href="https://www.theedgemarkets.com/node/654480"
            target="_blank"><i>Read More</i></a>
        </div>



      </div>
    </div>

    <!--SEC2 SMARTPHONEs-->
    <div class="contentCenter hidden-sm hidden-md hidden-lg">
      <div class="container col-sm-11 col-11">

        <!--New 7-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile" style="background-image:url('./assets/image/Component/P5-NEWS_7.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>October 26th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Former PSD DG is Ingenieur Gudang chairman</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links" href="https://www.theedgemarkets.com/article/former-psd-dg-ingenieur-gudang-chairman"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 6-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile" style="background-image:url('./assets/image/Component/P5-NEWS_7.png');
           ">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>October 13th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Ingenieur Gudang appoints former PSD director general as independent director</h4>
            </div>

            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-appoints-former-psd-director-general-independent-director"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--Mobile New 5-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>August 4th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Ingenieur Gudang sells properties back to former shareholder for RM26 mil</h4>
            </div>

            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-sells-properties-back-former-shareholder-rm26-mil"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 4-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>August 1st, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Ingenieur Gudang斥3045万购本查阿南镇工业地</h4>
            </div>

            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang%E6%96%A53045%E4%B8%87%E8%B4%AD%E6%9C%AC%E6%9F%A5%E9%98%BF%E5%8D%97%E9%95%87%E5%B7%A5%E4%B8%9A%E5%9C%B0"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 3-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>August 1st, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Ingenieur Gudang to buy industrial land in Puncak Alam for RM30.45 mil</h4>
            </div>

            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-buy-industrial-land-puncak-alam-rm3045-mil"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <!--New 2-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>May 18th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Dynaciate proposes to change name to Ingenieur Gudang</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-proposes-change-name-ingenieur-gudang"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <!--New 1-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/P5-NEWS_1(MAIN).png');">
          </div>
          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>Oct 7th, 2021 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>Dynaciate to acquire 8-acre industrial land in Serendah for RM10 mil</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-acquire-8acre-industrial-land-serendah-rm10-mil?type=malaysia"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <!--Original-->
        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/P5-NEWS_3.png');">
          </div>

          <div class="col-9" style="color:white;">
            <div class="col-12 mt-4 pl-0 mb-3 left">
              <p>October 19th, 2019 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 pl-0 pr-0 left">
              <h4>DMMAG buys Penang land from Dynaciate for RM41m to expand logistics business</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.edgeprop.my/content/1600702/mmag-buys-penang-land-dynaciate-rm41m-expand-logistics-business"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/P5-NEWS_4.png');"></div>
          <div class="col-9" style="color:white;">
            <div class="col-12 pl-0 mt-4 mb-3 left">
              <p>May 3rd, 2019 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 pl-0 mb-3 pr-0 left">
              <h4>Dynaciate bags two construction contracts worth RM35.6m</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-bags-two-construction-contracts-worth-rm356m"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/P5-NEWS_5.png');"></div>
          <div class="col-9" style="color:white;">
            <div class="col-12 pl-0 mt-4 mb-3 left">
              <p>Jan 11th, 2019 | TheSunDaily</p>
            </div>
            <div class="col-12 pl-0 mb-3 pr-0 left">
              <h4>Dynaciate Engineering Emerges As Tatt Giap’s Largest Shareholder</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.thesundaily.my/business/dynaciate-engineering-emerges-as-tatt-giap-s-largest-shareholder-CD371985"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-3 section2ImageMobile"
            style="background-image:url('./assets/image/Component/P5-NEWS_6.png');"></div>
          <div class="col-9" style="color:white;">
            <div class="col-12 pl-0 mt-4 mb-3 left">
              <p>November 22th, 2018 | TheStar</p>
            </div>
            <div class="col-12 pl-0 mb-3 pr-0 left">
              <h4>Tatt Giap to be known as Dynaciate</h4>
            </div>
            <div class="col-12 mb-3" style="text-align: right;">
              <a class="links"
                href="https://www.thestar.com.my/business/business-news/2018/11/22/tatt-giap-to-be-known-as-dynaciate"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>


      </div>

    </div>

    <!--SEC1 LAPTOPS & TABLETS-->
    <div class="contentCenter hidden-xs">
      <div class="container col-lg-9 col-md-10 contentCenter section1Image" style="display: flex; margin-top: 7%;">
        <!--New-->
        <div class="row contentCenter">
          <p>February 8th, 2023 | TheEdgeMarkets</p>
          <h4>Ingenieur Gudang to acquire interest in 164 units of retail lots at commercial complex</h4>
          <p class="mb-4">Ingenieur Gudang Bhd is buying a firm that has acquired the interest in 164 units of retail
            lots in a commercial complex in Pandan Perdana. </p>
          <a class="links mb-5" href="https://www.theedgemarkets.com/node/654480" target="_blank"><i>Read More</i></a>
        </div>

      </div>
    </div>



    <!--SEC2 LAPTOPS & TABLETS-->
    <div class="contentCenter hidden-xs mt-5">
      <div class="container col-lg-9 col-md-10">

        <!--New 7-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_7.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>October 26th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Former PSD DG is Ingenieur Gudang chairman</h4>
            </div>
            <div class="col-12 left">
              <p> Tan Sri Mohd Khairul Adib Abd Rahman, independent director of Ingenieur Gudang Bhd, formerly known as
                Dynaciate Group Bhd, has been redesignated as a non-executive chairman of the steel products
                manufacturer. </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links" href="https://www.theedgemarkets.com/article/former-psd-dg-ingenieur-gudang-chairman"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 6-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_7.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>October 13th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Ingenieur Gudang appoints former PSD director general as independent director</h4>
            </div>
            <div class="col-12 left">
              <p>Former director general of the Public Service Department (PSD), Tan Sri Mohd Khairul Adib Abd Rahman,
                has been appointed as an independent non-executive director by Ingenieur Gudang Bhd, effective
                immediately. </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-appoints-former-psd-director-general-independent-director"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 5-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>August 4th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Ingenieur Gudang sells properties back to former shareholder for RM26 mil</h4>
            </div>
            <div class="col-12 left">
              <p>Ingenieur Gudang Bhd, formerly known as Dynaciate Group Bhd, is selling two parcels of leasehold
                industrial land with buildings back to former shareholder Dynaciate Engineering Sdn Bhd (DESB) for RM26
                million. </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-sells-properties-back-former-shareholder-rm26-mil"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 4-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>August 1st, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Ingenieur Gudang斥3045万购本查阿南镇工业地</h4>
            </div>
            <div class="col-12 left">
              <p>前称鼎实集团（Dynaciate Group Bhd）的Ingenieur Gudang集团（Ingenieur Gudang
                Bhd）建议，以3045万令吉收购位于瓜拉雪兰莪本查阿南镇的9.32英亩有期工业用地。 </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang%E6%96%A53045%E4%B8%87%E8%B4%AD%E6%9C%AC%E6%9F%A5%E9%98%BF%E5%8D%97%E9%95%87%E5%B7%A5%E4%B8%9A%E5%9C%B0"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>
        <!--New 3-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>August 1st, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Ingenieur Gudang to buy industrial land in Puncak Alam for RM30.45 mil</h4>
            </div>
            <div class="col-12 left">
              <p>Ingenieur Gudang Bhd (formerly known as Dynaciate Group Bhd) has proposed to acquire leasehold
                industrial land measuring 9.32 acres in Puncak Alam, Kuala Selangor from Yuwang Development Sdn Bhd and
                Global Bloom Sdn Bhd for RM30.45 million. </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/ingenieur-gudang-buy-industrial-land-puncak-alam-rm3045-mil"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <!--New 2-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image"
            style="background-image:url('./assets/image/Component/Dynaciate-1-svdoff-prev-story_20220518223143_dynaciate.com_.my_.jpg');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>May 18th, 2022 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Dynaciate proposes to change name to Ingenieur Gudang</h4>
            </div>
            <div class="col-12 left">
              <p>Dynaciate Group Bhd is proposing to change its name to Ingenieur Gudang Bhd. </p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-proposes-change-name-ingenieur-gudang"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <!--New 1-->
        <div class="row mb-5 Background">
          <div class="col-4 section2Image"
            style="background-image:url('./assets/image/Component/P5-NEWS_1(MAIN).png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>Oct 7th, 2021 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Dynaciate to acquire 8-acre industrial land in Serendah for RM10 mil</h4>
            </div>
            <div class="col-12 left">
              <p>Dynaciate Group Bhd is acquiring an 8-acre piece of industrial land in Serendah,
                Selangor, from Brem Holdings Bhd for RM10.1 million.</p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-acquire-8acre-industrial-land-serendah-rm10-mil?type=malaysia"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_3.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>October 19th, 2019 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>DMMAG buys Penang land from Dynaciate for RM41m to expand logistics business</h4>
            </div>
            <div class="col-12 left">
              <p>MMAG buys Penang land from Dynaciate for RM41m to expand logistics business</p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.edgeprop.my/content/1600702/mmag-buys-penang-land-dynaciate-rm41m-expand-logistics-business"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_4.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>May 3rd, 2019 | TheEdgeMarkets</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Dynaciate bags two construction contracts worth RM35.6m</h4>
            </div>
            <div class="col-12 left">
              <p>Dynaciate bags two construction contracts worth RM35.6m- May 03, 2019</p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.theedgemarkets.com/article/dynaciate-bags-two-construction-contracts-worth-rm356m"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_5.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>Jan 11th, 2019 | TheSunDaily</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Dynaciate Engineering Emerges As Tatt Giap’s Largest Shareholder</h4>
            </div>
            <div class="col-12 left">
              <p>Dynaciate Engineering emerges as Tatt Giap’s largest shareholder-Jan 2019</p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.thesundaily.my/business/dynaciate-engineering-emerges-as-tatt-giap-s-largest-shareholder-CD371985"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

        <div class="row mb-5 Background">
          <div class="col-4 section2Image" style="background-image:url('./assets/image/Component/P5-NEWS_6.png');">
          </div>
          <div class="col-8" style="color:white;">
            <div class="col-7 mt-5 mb-5 left">
              <p>November 22th, 2018 | TheStar</p>
            </div>
            <div class="col-12 mb-3 left">
              <h4>Tatt Giap to be known as Dynaciate</h4>
            </div>
            <div class="col-12 left">
              <p>Tatt Giap to be known as Dynaciate-22 Nov 2018</p><br>
            </div>
            <div class="col-6 mb-5 left">
              <a class="links"
                href="https://www.thestar.com.my/business/business-news/2018/11/22/tatt-giap-to-be-known-as-dynaciate"
                target="_blank"><i>Read More</i></a>
            </div>
          </div>
        </div>

      </div>

    </div>


    <button *ngIf="isShow" class="back-to-top btn  btn-lg" (click)="scrollToTop()"> <i class="fas fa-angle-double-up" style="color: rgb(255, 255, 255);"></i> </button>
  </div>

  <app-footer></app-footer>