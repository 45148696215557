<!-- Desktop -->

	<div class="footer" style="background-color: transparent;">
        <div class="container">
          
          <div class="row" >
            <div class="col-md-12" style="text-align:center;">

              <a class="effect1"><span  (click)="goToPolicy()">PRIVACY POLICY</span></a>

            <span style="color:#ffffff;"> &nbsp; &nbsp; &nbsp; I&nbsp; &nbsp; &nbsp;&nbsp;</span>
            
            <a class="effect1"><span  (click)="goToDisclaimer()">DISCLAIMER</span></a>
              
              
              </div>
              </div>
          
          
          <div class="row">
            <div class="col-md-12" style="text-align:center;" >
              <p style="color: white;">© Copyright <span id="year"></span> INGENIEUR GUDANG BERHAD 
                Registration No. 200601012544 (732294-W). All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </div>

