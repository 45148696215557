import { Component, ViewChild } from '@angular/core';

import { Title, Meta } from '@angular/platform-browser'; 

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Ingeniuer';


  constructor(private titleService: Title, private metaService: Meta) {
    this.titleService.setTitle("Ingeniuer");
    
    const metaDesc = this.metaService.getTag('name=description'); 

    if(!metaDesc){

        this.metaService.addTags([
            {name: "description", content: ""}
        ]);
    }else{

        this.metaService.updateTag(  
            { name: "description", content: "" },  
            "name=description"  
          )  
    }
  
    
  }


}
