// Core Module
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Directive } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

// External Module 
import { NgxSpinnerModule } from "ngx-spinner";
// import { FacebookModule } from "ngx-facebook";
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { LivechatWidgetModule } from '@livechat/angular-widget';

// Component 
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { TncComponent } from './tnc/tnc.component';
import { TncCarriageComponent } from './tnc-carriage/tnc-carriage.component';
import { PolicyComponent } from './policy/policy.component';
import { PrivacyPolicyComponent } from './privacy_policy/privacy_policy.component';
import { InvestorComponent } from './investor/investor.component';
import { NewsComponent } from './news/news.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareerComponent } from './career/career.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { GroupStructureComponent } from './group_structure/group_structure.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CoreBusinessComponent } from './core-business/core-business.component';
// Animation 
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const routes: Routes = [
  { path: 'aboutus-component', component: AboutUsComponent },
];


@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    HeaderComponent,
    FooterComponent,
    ContactComponent,
    TncComponent,
    TncCarriageComponent,
    PolicyComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    // AppearDirectiveDirective
    NewsComponent,
    CareerComponent,
    InvestorComponent,
    OurTeamComponent,
    GroupStructureComponent,
    DisclaimerComponent,
    CoreBusinessComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxSpinnerModule,
    FormsModule,
    HttpClientModule,
    CommonModule,
    // FacebookModule.forRoot(),
    LivechatWidgetModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    // AppearDirectiveDirective
    // RouterModule.forRoot(routes, { anchorScrolling: 'enabled'})
    RouterModule.forRoot(routes,
      {
        scrollPositionRestoration: 'enabled'
      }
    ),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [Title],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})

export class AppModule { }

