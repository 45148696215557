<app-header></app-header>

<div class="section" style="margin-top: 5%; display: flex; justify-content: center; align-items:center;">
    <div class="container mt-5 pt-5">
      <div class="row" style="margin: 50px 0px;">
        <div class="col-md-12">
        
          <h3  style="text-align: center;">
            <strong>DISCLAIMER</strong>
          </h3> <br>

          <p>
            The  information  contained  in  this  website  is  for  general  information  purposes  only.  Ingenieur Gudang Berhad has used reasonable endeavors to ensure all the information and materials posted on  this  Website  are  correct  at  the  time  of  posting.  However,  we  make  no  representations  or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respectto the website or the information, products, services, or related graphics contained on the website for any purpose. 
            Any reliance you place on such information is therefore strictly at your own risk.
          </p>

          <p>
            In  no  event we shall liable  for  any  loss  or  damage  including  without  limitation,  indirect  or consequential  loss  or  damage,  or  any  loss  or  damage  whatsoever  arising  from  loss  of  data  or profits arising out of or in connection with the use of this website.The information and materials including the conditions in this website may be modified, deleted or replaced from time to time and at any time at the discretion of Ingenieur Gudang Berhad.
          </p>

          <p>
            Every effort is made to keep the website up and running smoothly. However, Ingenieur Gudang Berhad  shall  take  no  responsibility  for  and  will  not  be  liable  for  the  website  being  temporarily unavailable due to technical issues beyond our control.          
          </p>

          <p>
            This website may be able to link to other websites which are not under our control. We have no knowledge of or control over the nature,content, and availability of those websites. We do not sponsor, recommend, or endorse anything contained on these linked websites. We do not accept any liability or responsibility of any description for any loss suffered by you by relying on anything contained or not contained on these linked websites.
          </p>

          <p style="margin-bottom: 15%;">
            By  continuing to  use  this  site,  you  are  considered  to  have  understood  and  agreed  to  the aforementioned.
          </p>
  
         
        </div>
      </div>
    </div>
  </div>

  <app-footer class="footer"></app-footer>