import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';  
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiService } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter } from 'rxjs/operators';
import { ViewportScroller } from '@angular/common';

import Swal from 'sweetalert2'


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) { }

  async ngOnInit() {

    this.titleService.setTitle("Home | Ingenieur")

    const metaDesc = this.metaService.getTag('name=description'); 

    if(!metaDesc){

        this.metaService.addTags([
            {name: "description", content: ""}
        ]);
    }else{

        this.metaService.updateTag(  
            {name: "description", content: ""},  
            "name=description"  
          )  
    }

  }

  
}
