<app-header></app-header>

<div class="backgroundBW content">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <div class="section1background">
        <div class="container section1ContainerPosition center">
            <div class="row col-sm-12 col-12 center">
                <p style="text-align:center;">The Group recognises the value of transparent, consistent and coherent communications with shareholders consistent with commercial confidentiality and regulatory considerations. 
                   The Group aims to build long-term relationships with its shareholders and potential 
                   investors through appropriate channels for disclosure of information.</p>
                  
            </div>
        </div>
    </div>

    <div class="section2background center">
        <div class="col-sm-8 col-12 " style="margin-top: 4%; margin-bottom:2.5%">
            <div id="accordion">
                <div class="card col-sm-12 col-12" style="height: auto;">
                  <div class="card-header card-mobile pl-0 pr-0 pt-0 pb-2" id="headingOne" style="background-color: white;">
                    <h5 class="mb-0" style="width: -webkit-fill-available;">
                      <button class="btn btn-link collapsed click col-sm-12 col-12" data-toggle="collapse" data-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne" style="text-decoration: none; max-height: 50%;">
                          <h4 class="buttonHeight" style="text-align: left; color:black; float:left;">Bursa Announcements&nbsp;&nbsp;&nbsp;&nbsp;</h4>
                          <span class="fa fa-sort-desc right" style="float:right; font-size: x-large;" aria-hidden="true"></span>
                      </button>
                    </h5>
                  </div>
                  <div id="collapseOne" class="collapse hide" aria-labelledby="headingOne" data-parent="#accordion">
                    <table class="card-body col-md-12">
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToAdditionalListingAnnouncement()">
                        <p class="section2BtnList">Additional Listing Announcement /Subdivision of Shares</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToAnnualAuditedAccount()">
                        <p class="section2BtnList">Annual Audited Account</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToAnnualReport()">
                        <p class="section2BtnList">Annual Report</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToChangeofCorporateInformation()">
                        <p class="section2BtnList">Change of Corporate Information</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToChangesinShareholdings()">
                        <p class="section2BtnList">Changes in Shareholdings</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToNoticetoShareholders()">
                        <p class="section2BtnList">Circular/Notice to Shareholders</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToDealingsinListedSecurities()">
                        <p class="section2BtnList">Dealings in Listed Securities (Chapter 14 of Listing Requirements)</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToDelistingofSecurities()">
                        <p class="section2BtnList">Delisting of Securities</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToEntitlements()">
                        <p class="section2BtnList">Entitlements</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToTerminationofSecurities()">
                        <p class="section2BtnList">Expiry / Maturity / Termination of Securities</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; height: -webkit-fill-available;text-align: left;" (click)="goToFinanceResults()">
                          <p class="section2BtnList">Financial Results</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToGeneralAnnouncement()">
                          <p class="section2BtnList">General Announcement</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToGeneralMeetings()">
                          <p class="section2BtnList">General Meetings</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToImportantRelevantDatesforRenounceableRights()">
                          <p class="section2BtnList">Important Relevant Dates for Renounceable Rights</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToInvestorAlert()">
                          <p class="section2BtnList">Investor Alert</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToListingCirculars()">
                        <p class="section2BtnList">Listing Circulars</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToListingInformationandProfile()">
                          <p class="section2BtnList">Listing Information and Profile</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToProspectus()">
                        <p class="section2BtnList">Prospectus</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToReplytoQuery()">
                        <p class="section2BtnList">Reply to Query</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>                      
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToSharesBuyBack()">
                          <p class="section2BtnList">Shares Buy Back</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToTakeoverOffer()">
                        <p class="section2BtnList">Take-over Offer</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToTransferofListing()">
                          <p class="section2BtnList">Transfer of Listing</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>                      
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToUnusualMarketActivity()">
                          <p class="section2BtnList">Unusual Market Activity</p>
                          <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                      </button>
                      <button class="section2-bursaBtn" style="border:none; width: -webkit-fill-available; text-align: left;" (click)="goToDisclosureOnQuarterlyProduction()">
                        <p class="section2BtnList">Disclosure On Quarterly Production</p>
                        <i class="fa fa-chevron-right mt-3" aria-hidden="true" style="float: right;"></i>
                    </button>
                    </table>
                  </div>
                </div>
              </div>

        </div>
    </div>

    <div class="section3">
        <div class="container">
            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>17th ANNUAL GENERAL MEETING</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>24 April 2024 (Wednesday) &nbsp; | &nbsp; 11.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-8 col-8 mb-4 center">
                    <p>Ballroom V, Main Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan, Malaysia.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-lg-3 col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeofthe17thagm()">
                        <p class="section3Text">Notice of the 17th AGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm17agm()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide17agm()">
                        <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAnnualReport202317agm()">
                        <p class="section3Text">Annual Report 2023</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToCGReport202317agm()">
                        <p class="section3Text">CG Report 2023</p>
                    </button>
                </div>
                <div class="row mb-3 center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders220424()">
                       <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to the Proposed Renewal 
                            of Shareholders' Mandate for the Recurrent Related Party Transactions of a Revenue of Trading Nature (Circular)</p>
                       </div>
                    </button>
                </div>
                <div class="row mb-3 center">
                    <button class="col-lg-3 col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToMinutesof17agm()">
                        <p class="section3Text">Minutes of AGM</p>
                    </button>
                </div>
             </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>Extraordinary General Meeting ("EGM")</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>21 March 2024 (Thursday) &nbsp; | &nbsp; 11.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-10 col-10 mb-4 center">
                    <p>Ballroom V, Main Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeoftheEGM210324()">
                       <p class="section3Text">Notice of the EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm210324()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-4 col-sm-10 col-10 border btnPosition pdfBackground section3Btn" (click)="goToAdministrativeGuide210324()">
                       <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders210324()">
                       <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to Proposed Bonus Issue of Warrants</p>
                       </div>
                    </button>
                </div>
            </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>16th ANNUAL GENERAL MEETING</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>27 April 2023 (Thursday) &nbsp; | &nbsp; 11.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-8 col-8 mb-4 center">
                    <p>Ballroom V, Main Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan, Malaysia.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-lg-3 col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeofthe16thAGM16agm()">
                        <p class="section3Text">Notice of the 16th AGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm16agm()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide16agm()">
                        <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAnnualReport2023042716agm()">
                        <p class="section3Text">Annual Report 2022</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToCGReport2023042716agm()">
                        <p class="section3Text">CG Report 2022</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders16agm()">
                       <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to the Proposed Renewal of Shareholders' Mandate for the Recurrent Related Party Transactions of a Revenue or Trading Nature (Circular)</p>
                       </div>
                    </button>
                </div>
             </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>Extraordinary General Meeting ("EGM")</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>16 November 2022 (Wednesday) &nbsp; | &nbsp; 11.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-10 col-10 mb-4 center">
                    <p>Ballroom V, Main Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling
                        Jaya, Selangor Darul Ehsan.
                        </p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeoftheEGM161122()">
                       <p class="section3Text">Notice of the EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm161122()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-4 col-sm-10 col-10 border btnPosition pdfBackground section3Btn" (click)="goToAdministrativeGuide161122()">
                       <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3Btn section3MediumText" (click)="goToCirculartoShareholders161122()">
                        <div class="col-12 pl-0">
                            <p class="mediumText" style="margin-bottom: 0%;">Circular to Shareholders in relation to proposals dated 1 Nov 2022 (_Circular_)</p>
                        </div>
                    </button>
                </div>
            </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>Extraordinary General Meeting ("EGM")</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>5 September 2022 (Monday) &nbsp; | &nbsp; 2.00 pm</p><br>
                </div>
                <div class="col-md-8 col-sm-10 col-10 mb-4 center">
                    <p>Greens III, Sports Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeoftheEGM050922()">
                       <p class="section3Text">Notice of the EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm050922()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-4 col-sm-10 col-10 border btnPosition pdfBackground section3Btn" (click)="goToAdministrativeGuide050922()">
                       <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3Btn section3MediumText" (click)="goToCirculartoShareholders050922()">
                        <div class="col-12 pl-0">
                            <p class="mediumText" style="margin-bottom: 0%;">Circular to Shareholders in relation to Proposed Penang Property 
                               Disposal (_Circular_)</p>
                        </div>
                    </button>
                </div>
            </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>Extraordinary General Meeting ("EGM")</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>26 July 2022 (Tuesday) &nbsp; | &nbsp; 10.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-8 col-8 mb-4 center">
                    <p>Ballroom V, Main Wing, Jalan Kelab Tropicana, Tropicana Golf & Country Resort, 47410 Petaling Jaya, Selangor Darul Ehsan.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeoftheEGM260722()">
                        <p class="section3Text">Notice of the EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm260722()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide260722()">
                        <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPositon border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders260722()">   
                        <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to Proposed 40%
                              Private Placement and Proposed Change of Company Name</p>
                        </div>
                    </button>
                </div>
           </div>

            <div class="row mt-5 mb-5 pt-5 center">
               <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                   <h4>15th ANNUAL GENERAL MEETING</h4>
               </div>
               <div class="col-md-12 col-sm-12 col-12 center">
                   <p>27 April 2022 (Wednesday) &nbsp; | &nbsp; 9.30 am</p><br>
               </div>
               <div class="col-md-8 col-sm-8 col-8 mb-4 center">
                   <p>ME Hub Kota Damansara, 3rd Floor, 28-3, Jalan PJU 5/4, Dataran Sunway, Kota Damansara, 47810 Petaling Jaya, Selangor, Malaysia.</p>
               </div>
               <div class="row mb-3 center">
                   <button class="col-lg-3 col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeofthe15thAGM15agm()">
                       <p class="section3Text">Notice of the 15th EGM</p>
                   </button>
                   <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm15agm()">
                       <p class="section3Text">Proxy Form</p>
                   </button>
                   <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide15agm()">
                       <p class="section3Text">Administrative Guide</p>
                   </button>
               </div>
               <div class="row mb-3 center">
                   <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAnnualReport202115agm()">
                       <p class="section3Text">Annual Report 2021</p>
                   </button>
                   <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToCGReport202115agm()">
                       <p class="section3Text">CG Report 2021</p>
                   </button>
               </div>
               <div class="row center">
                   <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders15agm()">
                      <div class="col-12 pl-0">
                          <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to the Proposed Renewal of
                             Shareholders' Mandate for the Recurrent Related Party Transactions of a Revenue or Trading Nature (''Circular'')</p>
                      </div>
                   </button>
               </div>
            </div>

            <div class="row mt-5 mb-5 pt-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 center">
                    <h4>Extraordinary General Meeting ("EGM")</h4>
                </div>
                <div class="col-md-12 col-sm-12 col-12 center">
                    <p>16 November 2021 (Tuesday) &nbsp; | &nbsp; 11.00 am</p><br>
                </div>
                <div class="col-md-8 col-sm-8 col-8 mb-4 center">
                    <p>ME Hub Kota Damansara, 3rd Floor, 28-3, Jalan PJU 5/4, Dataran Sunway, Kota Damansara, 47810 Petaling Jaya, Selangor, Malaysia.</p>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeoftheEGM161121()">
                       <p class="section3Text">Notice of the EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm161121()">
                       <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide161121()">
                       <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 btnPosition border pdfBackground section3BigBtn" (click)="goToCirculartoShareholders161121()">
                        <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in relation to the Proposed Acquisition of MGSB from MMAG and Proposed Settlement
                              (collectively, ''Proposals'') and Proposed New Shareholders' Mandate for New Recurrent Related Party
                              Transactions of a Revenue or Trading Nature (''Circular'')</p>
                        </div>
                    </button>
                </div>
              </div>

              <div class="row mt-5 mb-5 pt-5 pb-5 center">
                <div class="col-md-12 col-sm-12 col-12 mt-5 mb-4 center">
                    <h4>14th ANNUAL GENERAL MEETING</h4>
                </div>
                <div class="row mb-3 center">
                    <button class="col-lg-3 col-md-4 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToNoticeofthe14thAGM14agm()">
                        <p class="section3Text">Notice of the 14th EGM</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToProxyForm14agm()">
                        <p class="section3Text">Proxy Form</p>
                    </button>
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAdministrativeGuide14agm()">
                        <p class="section3Text">Administrative Guide</p>
                    </button>
                </div>
                <div class="row mb-3 center">
                    <button class="col-md-3 col-sm-10 col-10 btnPosition border pdfBackground section3Btn" (click)="goToAnnualReport202114agm()">
                       <p class="section3Text">Annual Report 2021</p>
                   </button>
                </div>
                <div class="row center">
                    <button class="row col-md-11 col-sm-10 col-10 border mb-5 pdfBackground section3BigBtn" (click)="goToCirculartoShareholders14agm()">
                        <div class="col-12 pl-0">
                           <p class="section3LargeText" style="margin-bottom: 0%;">Circular to Shareholders in
                              relation to the Proposed Renewal of Shareholders' Mandate for the Recurrent Related Party Transactions of a
                              Revenue or Trading Nature (''Circular'')</p>
                        </div>
                    </button>
                </div>
              </div>
        </div>
        <!-- <button *ngIf="isShow" class="back-to-top btn  btn-lg" (click)="scrollToTop()"> <i class="fas fa-angle-double-up" style="color: rgb(255, 255, 255);"></i> </button> -->
       
        <!-- <button *ngIf="isShow" id="back-to-top-btn" (click)="scrollToTop()"><i class="fas fa-angle-double-up"></i></button> -->
       
    </div>
    
</div>



<app-footer></app-footer>