<app-header></app-header>

<div class="section">
      <div class="contents">
            <h3 style="color:whitesmoke;text-align: center;">Group Structure</h3>
            <br />
            <img class="center-block img-responsive hidden-sm hidden-xs"
                  src="./assets/image/Component/P2-GROUP_STRUCTURE(DESKTOP).png">
            <img class="center-block img-responsive hidden-lg hidden-md"
                  src="./assets/image/Component/P2-GROUP_STRUCTURE(MOBILE).png">





      </div>

</div>


<app-footer></app-footer>