import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'; 

@Component({
  selector: 'app-investor',
  templateUrl: './investor.component.html',
  styleUrls: ['./investor.component.css']
})
export class InvestorComponent implements OnInit {
  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Investors | Ingenieur")
  }

  //function - dropdown list
  onclick(event): void {
    let checkList = document.getElementById('list1');
    checkList.click();
    if (checkList.classList.contains('visible')) {
      checkList.classList.remove('visible');
    }
    else {
      checkList.classList.add('visible');
    }
  }

  //SECTION2-BURSA
  goToFinanceResults(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/FA,FRCO', '_blank');
  }

  goToAnnualReport(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/AR,ARCO', '_blank');
  }

  goToNoticetoShareholders(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/CS,CSCO', '_blank');
  }

  goToAdditionalListingAnnouncement(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/AL,ALCO', '_blank');
  }

  goToAnnualAuditedAccount(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/AA,AACO', '_blank');
  }

  goToChangeofCorporateInformation(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/CI,COCI', '_blank');
  }
  
  goToChangesinShareholdings(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/SH,CHSH', '_blank');
  }

  goToEntitlements(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/EA,ENCO', '_blank');
  }

  goToGeneralAnnouncement(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/GA,GACO', '_blank');
  }

  goToGeneralMeetings(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/GM,MECO', '_blank');
  }

  goToImportantRelevantDatesforRenounceableRights(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/TR', '_blank');
  }

  goToInvestorAlert(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/IA,IACO', '_blank');
  }

  goToListingInformationandProfile(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/IP,LICO', '_blank');
  }

  goToSharesBuyBack(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/SB,SBBA', '_blank');
  }

  goToTransferofListing(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/TL,TRFL', '_blank');
  }

  goToReplytoQuery(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/RQ,RQCO', '_blank');
  }

  goToUnusualMarketActivity(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/UMA,UMCO', '_blank');
  }

  goToDelistingofSecurities(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/DLCO', '_blank');
  }

  goToTakeoverOffer(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/TECO', '_blank');
  }

  goToTerminationofSecurities(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/ES,EMCO', '_blank');
  }

  goToDealingsinListedSecurities(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/DRCO', '_blank');
  }

  goToListingCirculars(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/LC,LCCO', '_blank');
  }

  goToProspectus(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/PP,PPCO', '_blank');
  }
  
  goToDisclosureOnQuarterlyProduction(){
    window.open('https://www.bursamalaysia.com/market/listed-companies/company-announcements/subscribe/5178/option2/DMCO', '_blank');
  }

   //SECTION3-18agm
   goToNoticeofthe18thagm(){
    window.open('./doc/18_agm/Notice_of_the_18th_AGM.pdf', '_blank');
  }

  goToProxyForm18agm(){
    window.open('./doc/18_agm/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide18agm(){
    window.open('./doc/18_agm/Administrative_Guide.pdf', '_blank');
  }

  goToAnnualReport202418agm(){
    window.open('./doc/18_agm/Annual_Report_2024.pdf', '_blank');
  }

  goToCGReport202418agm(){
    window.open('./doc/18_agm/CG_Report_2024.pdf', '_blank');
  }

  goToCirculartoShareholders202418agm(){
    window.open('./doc/18_agm/RRPT_Circular_2024.pdf', '_blank');
  }

   //SECTION3-17agm
   goToNoticeofthe17thagm(){
    window.open('./doc/17_agm/Notice_of_the_17th_AGM.pdf', '_blank');
  }

  goToProxyForm17agm(){
    window.open('./doc/17_agm/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide17agm(){
    window.open('./doc/17_agm/Administrative_Guide.pdf', '_blank');
  }

  goToAnnualReport202317agm(){
    window.open('./doc/17_agm/Annual_Report_2023.pdf', '_blank');
  }

  goToCGReport202317agm(){
    window.open('./doc/17_agm/CG_Report_2023.pdf', '_blank');
  }

  goToCirculartoShareholders220424(){
    window.open('./doc/17_agm/RRPT_Circular_2024.pdf', '_blank');
  }

  goToMinutesof17agm(){
    window.open('./doc/17_agm/Minutes_of_AGM_2024.pdf', '_blank');
  }

   //SECTION3-16agm
   goToNoticeofthe16thAGM16agm(){
    window.open('./doc/16_agm/Notice_of_the_16th_AGM.pdf', '_blank');
  }

  goToProxyForm16agm(){
    window.open('./doc/16_agm/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide16agm(){
    window.open('./doc/16_agm/Administrative_Guide.pdf', '_blank');
  }

  goToAnnualReport2023042716agm(){
    window.open('./doc/16_agm/IGB_Annual_Report_2022.pdf', '_blank');
  }

  goToCGReport2023042716agm(){
    window.open('./doc/16_agm/IGB_CG_Report_2022.pdf', '_blank');
  }

  goToCirculartoShareholders16agm(){
    window.open('./doc/16_agm/Circular_to_Shareholders_in_relation_to_the_Proposed_Renewal_of_Shareholders_Mandate_for_the_Recurrent_Related_Party_Transactions_of_a_Revenue_or_Trading Nature_Circular.pdf', '_blank');
  }

  //SECTION3-161122
  goToNoticeoftheEGM161122(){
    window.open('./doc/egm161122/Notice_of_the_EGM.pdf', '_blank');
  }

  goToProxyForm161122(){
    window.open('./doc/egm161122/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide161122(){
    window.open('./doc/egm161122/Administrative_Guide.pdf', '_blank');
  }

  goToCirculartoShareholders161122(){
    window.open('./doc/egm161122/Circular_to_Shareholders_in_relation_to_proposals_dated_1_Nov 2022_Circular.pdf', '_blank');
  }

  //SECTION3-050922
  goToNoticeoftheEGM050922(){
    window.open('./doc/egm050922/Notice_of_the_EGM.pdf', '_blank');
  }

  goToProxyForm050922(){
    window.open('./doc/egm050922/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide050922(){
    window.open('./doc/egm050922/Administrative_Guide.pdf', '_blank');
  }

  goToCirculartoShareholders050922(){
    window.open('./doc/egm050922/Circular_to_Shareholders_in_relation_to_Proposed_Penang_Property_Disposal_Circular.pdf', '_blank');
  }

  //SECTION3-260722
  goToNoticeoftheEGM260722(){
    window.open('./doc/egm260722/Notice_of_the_EGM.pdf', '_blank');
  }

  goToProxyForm260722(){
    window.open('./doc/egm260722/Proxy_Form.pdf', '_blank');
  }

  goToAdministrativeGuide260722(){
    window.open('./doc/egm260722/Administrative_Guide.pdf', '_blank');
  }

  goToCirculartoShareholders260722(){
    window.open('./doc/egm260722/Circular_to_Shareholders_in_relation_to_Proposed_40_Private_Placement_and_Proposed_Change_of_Company_Name.pdf', '_blank');
  }

   //SECTION3-15agm
   goToNoticeofthe15thAGM15agm(){
    window.open('./doc/15_agm/Notice_of_the_15th_AGM.pdf', '_blank');
  }

  goToProxyForm15agm(){
    window.open('./doc/15_agm/Form_of_Proxy.pdf', '_blank');
  }

  goToAdministrativeGuide15agm(){
    window.open('./doc/15_agm/Administrative_Guide.pdf', '_blank');
  }

  goToAnnualReport202115agm(){
    window.open('./doc/15_agm/Annual_Report_2021.pdf', '_blank');
  }

  goToCGReport202115agm(){
    window.open('./doc/15_agm/CG_Report_2021.pdf', '_blank');
  }

  goToCirculartoShareholders15agm(){
    window.open('./doc/15_agm/Circular_to_Shareholders_in_relation_to_the_Proposed_Renewal_of_Shareholders_Mandate_for_the_Recurrent_Related_Party_Transactions_of_a_Revenue_or_Trading_Nature_Circular.pdf', '_blank');
  }

  //SECTION3-161121
  goToNoticeoftheEGM161121(){
    window.open('./doc/egm161121/Notice_of_EGM.pdf', '_blank');
  }

  goToProxyForm161121(){
    window.open('./doc/egm161121/Form_of_Proxy.pdf', '_blank');
  }

  goToAdministrativeGuide161121(){
    window.open('./doc/egm161121/Administrative_Guide.pdf', '_blank');
  }

  goToCirculartoShareholders161121(){
    window.open('./doc/egm161121/DGB_Circular.pdf', '_blank');
  }

     //SECTION3-14agm
     goToNoticeofthe14thAGM14agm(){
      window.open('./doc/14_agm/Notice_of_AGM.pdf', '_blank');
    }
  
    goToProxyForm14agm(){
      window.open('./doc/14_agm/Proxy_Form.pdf', '_blank');
    }
  
    goToAdministrativeGuide14agm(){
      window.open('./doc/14_agm/Administrative_Guide.pdf', '_blank');
    }
  
    goToAnnualReport202114agm(){
      window.open('./doc/14_agm/DGBAR_2020.pdf', '_blank');
    }
  
    goToCirculartoShareholders14agm(){
      window.open('./doc/14_agm/Circular_RRPT.pdf', '_blank');
    }

//SECTION3-210324
goToNoticeoftheEGM210324(){
  window.open('./doc/egm210324/Notice_of_EGM.pdf', '_blank');
}

goToProxyForm210324(){
  window.open('./doc/egm210324/Proxy_Form.pdf', '_blank');
}

goToAdministrativeGuide210324(){
  window.open('./doc/egm210324/Administrative_Guide.pdf', '_blank');
}

goToCirculartoShareholders210324(){
  window.open('./doc/egm210324/Ingenieur_Gudang _Circular.pdf', '_blank');
}

isShow: boolean = false;
topPosToStartShowing = 100;

@HostListener('window:scroll')
checkScroll() {
  const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

  this.isShow = scrollPosition >= this.topPosToStartShowing;
}

scrollToTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}

}
