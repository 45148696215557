<!--SMARTPHONE-->
<div class="navbar navbar-default navbar-fixed-top hidden-sm hidden-md hidden-lg" style="background-color: #242424;">
  <div class="container" style="margin: 0;">
    <div class="navbar-header">

      <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#navbar-ex-collapse" style="border: none; display:flex; justify-content:right; background-color: transparent;">
        <img class="img-responsive" src="./assets/image/Icons/MOBILEMENUBUTTON.png">
      </button>

       <a  class="navbar-brand navbar-brand-mobile" style="cursor: pointer;" (click)="goToLanding()"><img height="100%" alt="Brand" 
        src="./assets/image/Logo/FULL_LOGO_(TRANSPARENT).png" class="center-block" id="logo"></a>

    </div>

    <div class="collapse navbar-collapse" id="navbar-ex-collapse">
      <ul class="nav navbar-nav navbar-right">

        <div class="dropdown col-sm-12 c0l-12">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-1 pb-1" (click)="goToLanding()">Home</span></button><br>
        </div>

        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-2 pb-2" (click)="goToAboutUs()">About Us</span></button>
        </div>

        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-2 pb-2" (click)="goToCoreBusiness()">Core Business</span></button>
        </div>

        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-2 pb-2" (click)="goToInvestor()">Investor Relations</span></button>
        </div>

        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-2 pb-2" (click)="goToNews()">News</span></button>
        </div>
    
        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-1" style="float:right; text-align: right;"><span class="effect pt-2 pb-2" (click)="goToCareer()">Career</span></button>
        </div>

        <div class="dropdown col-sm-12 c0l-12 border-top">
          <button class="dropbtn-mobile pt-1 pb-0" style="float:right; text-align: right;"><span class="effect pt-2 pb-0" (click)="goToContact()">Contact Us</span></button>
        </div>

      </ul>

    </div>

  </div>
</div>


<!--LAPTOP-->
  <div class="navbar navbar-default navbar-fixed-top hidden-xs" style="background-color: #242424;">
    <div class="container col-lg-10" style="margin: 0;">
      <div class="row">
      <div class="navbar-header col-md-3 col-lg-3" style="display:flex; justify-content:center">

         <a class="navbar-brand pt-0 pb-0" style="cursor: pointer;" (click)="goToLanding()"><img height="100%" alt="Brand" 
            src="./assets/image/Logo/FULL_LOGO_(TRANSPARENT).png" class="center-block" id="logo"></a>
      
        </div>

      
      <div class="collapse navbar-collapse col-md-9 col-lg-9" id="navbar-ex-collapse" style="display: flex; justify-content:center; text-align: center;">
        <ul class="nav navbar-nav navbar-right" style="display: flex; align-items:center; justify-content:center;">


          <div class="dropdown">
            <button class="dropbtn"><span class="effect" (click)="goToLanding()">Home</span></button>
          </div>

          <!-- <div class="dropdown">
            <button class="dropbtn"><span class="effect" (click)="goToAboutUs()">About Us</span></button>
          </div> -->


    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToAboutUs()">About Us</span></button>
    </div>

       <!-- <div class="dropdown">
      <button class="dropbtn"><span class="effect"> Products</span></button>
      <div class="dropdown-content">
       <li style="cursor: pointer;"> 
        <a (click)="goToProduct()">Quality Policy, Stringent Quality Control, Product Compliances</a>
      </li>
      <li style="cursor: pointer;"> 
      <a (click)="goToProductSpec()">Product Specification</a>
      </li>
  
      </div>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToEsgCompliance()">ESG Compliance</span></button>
    </div> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToCoreBusiness()">Core Business</span></button>
    </div>
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToInvestor()">Investor Relations</span></button>
    </div>
    <!-- <li style="cursor: pointer;">
      <a class="dropbtn" (click)="goToEsgCompliance()"><span class="effect">ESG Compliance</span></a>
    </li> -->

    <!-- <li style="cursor: pointer;">
      <a (click)="goToNews()"><span class="effect">News</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToNews()">News</span></button>
    </div>

    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToCareer()">Career</span></button>
    </div>

    <!-- <li style="cursor: pointer;">
      <a (click)="goToContact()"><span class="effect">Contact Us</span></a>
    </li> -->
    <div class="dropdown">
      <button class="dropbtn"><span class="effect" (click)="goToContact()">Contact Us</span></button>
    </div>

    <!-- <li style="cursor: pointer;">
      <a (click)="goToContact()"><span class="effect">Career</span></a>
    </li> -->
    </ul>

      </div>
    </div>
    </div>
  </div>
