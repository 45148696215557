<app-header></app-header>

<!--CONSTRUCTION-->
<div class="section mainBackground tabcontent" id="construction" style="display:block;">
   <meta name="viewport" content="width=device-width, initial-scale=1">

      <!--SECTION1-->
      <div class="section1ConstructionBackground">
        <div class="container section1ContainerPosition" style="position: relative; top:45%">
          <h4>Core Business</h4><br>
          <div class="container">     
            <div class="row">
              <div class="col-sm-8 col-md-3 col-lg-3 section1buttons">
                <button id="btn_construction" class="tablinks active br constructionBtn section1btn" (click)="openCity('construction')">
                  <span class="fa fa-angle-right hidden-sm hidden-md hidden-lg">&nbsp;</span>
                  Construction Segment</button>
                <button id="btn_investment" class="tablinks section1btn brsmall" (click)="openCity('investment')">
                  <span class="fa fa-angle-right hidden-sm hidden-md hidden-lg">&nbsp;</span>
                  Investment Properties & Others segment</button>
              </div>
              <div class="col-sm-9 col-md-7 col-lg-6 section1content">
                <p>Professional discipline that deals with the designing, planning, construction and management
                  of infrastructures such as roads, tunnels, bridges, facilities, buildings and other projects.</p>     
              </div>
            </div>
          </div>
        </div>
      </div>   

      <!--SECTION2-->
      <div class="section2background" style="height: auto;">
        <div class="container section2Content mx-auto" style="text-align: center; color:white;">
            <p style="margin: 0%;">Today, the Group is in the midst of negotiating for an EPCC (Engineering, Procurement, Construction and Commissioning) contract as part of a sizeable project involving construction of 
              a chemical plant in addition to active participation in potential project biddings.</p>
        </div>
      </div>

      <!--SECTION3-->
        <div class="container section3">
           <div class="row pb-5" style="text-align: center;">
            <h4>Construction Segment</h4>
           </div>

            <div class="section3-contents px-sm-5 mx-sm-4">
              <div class="containerNav nav-bar">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <button id="btn_construction" class="tablinks nav-link section3btn"
                    (click)="openCity('construction')">Construction Segment</button>
                  </li>
                  <li class="nav-item">
                    <button id="btn_investment" class="tablinks nav-link investmentbtn" (click)="openCity('investment')">Investment Properties
                      & Others segment</button>
                  </li>
                </ul>
              </div>
            </div>

           <div class="row mt-5 pb-5">
              <div class="card-segment1 card1 col-md-8 col-sm-10 col-9 mx-auto embed-responsive">
                <img class="card-img-top img-fluid" src="./assets/image/Component/P3-SEGMENT_1.png" alt="Card image">
                <div class="card-img-overlay" style="display: flex; justify-content:center; align-items: center;">
                   <h4>Temporary construction facilities</h4>
                </div>
              </div>
           </div>

           <!--
           <div class="row pb-5">
              <div class="card-segment1 card2 col-md-8 col-sm-10 col-9 mx-auto embed-responsive">
                <img class="card-img-top" src="./assets/image/Component/P3-SEGMENT_2.png" alt="Card image">
                <div class="card-img-overlay" style="display: flex; justify-content:center; align-items: center;">
                   <h4 class="card-contain">Piling & Foundation works</h4>
                </div>
              </div>
           </div>

           <div class="row pb-5">
              <div class="card-segment1 card3 col-md-8 col-sm-10 col-9 mx-auto embed-responsive">
                <img class="card-img-top" src="./assets/image/Component/P3-SEGMENT_3.png" alt="Card image">
                <div class="card-img-overlay" style="display: flex; justify-content:center; align-items: center;">
                   <h4 class="card-contain">Earthworks, road and drainage works</h4>
                </div>
              </div>
           </div>
           -->

           <div class="row pb-5">
              <div class="card-segment1 card4 col-md-8 col-sm-10 col-9 mx-auto embed-responsive">
                <img class="card-img-top img-fluid" src="./assets/image/Component/P3-SEGMENT_4.png" alt="Card image">
                <div class="card-img-overlay" style="display: flex; justify-content:center; align-items: center;">
                   <h4>Production & Administration buildings</h4>
                </div>
              </div>
           </div>

           <div class="row pb-5">
              <div class="card-segment1 card5 col-md-8 col-sm-10 col-9 mx-auto embed-responsive">
                <img class="card-img-top img-fluid" src="./assets/image/Component/P3-SEGMENT_5.png" alt="Card image">
                <div class="card-img-overlay" style="display: flex; justify-content:center; align-items: center;">
                   <h4>Warehouses and workshops</h4>
                </div>
              </div>
           </div>
        </div>
</div>

<!--INVESTMENT-->
<div class="section mainBackground tabcontent" id="investment" style="display:block;">
  <meta name="viewport" content="width=device-width, initial-scale=1">

     <!--SECTION1-->
     <div class="section1InvestmentBackground">
       <div class="container section1ContainerPosition" style="position: relative; top:45%">
         <h4>Core Business</h4><br>
         <div class="container">     
           <div class="row">
             <div class="col-sm-8 col-md-3 col-lg-3 section1buttons">
               <button id="btn_construction" class="tablinks br section1btn" (click)="openCity('construction')">
                <span class="fa fa-angle-right hidden-sm hidden-md hidden-lg">&nbsp;</span>
                Construction Segment</button>
               <button id="btn_investment" class="tablinks brsmall investmentBtn active section1btn" (click)="openCity('investment')">
                <span class="fa fa-angle-right hidden-sm hidden-md hidden-lg">&nbsp;</span>
                Investment Properties & Others segment</button>
             </div>
             <div class="col-sm-9 col-md-7 col-lg-6 section1content">
               <p>Property include land and building that is held to earn rental or for capital or both which are not owner-occupied or not used in production, goods supply, 
                administration and not held for sale in the ordinary course of business.</p>     
             </div>
           </div>
         </div>
       </div>
     </div>

     <!--SECTION2-->
     <div class="section2background" style="height: auto;">
      <div class="container section2Content mx-auto" style="text-align: center; color:white;">
          <p>New opportunities are still aplenty in these changing markets and the real estate sector (particularly for industrial buildings) is ready to capture the pent-up demand following the boom of 
            e-commerce and online sales strategies which requires warehouses and storage spaces.</p><br><br><br>
          <p class="brsmall" style="text-align: left; font-weight:bold;">The Group’s investment strategies include:</p>
          <ul style="text-align: left;"><li><p>Logistics facilities and manufacturing facilities with long leases from tenants with strong covenants</p></li></ul>
          <ul style="text-align: left;"><li><p>Well-located industrial land suitable to be developed for industrial use or logistics warehousing in locations ideal for last-mile distribution</p></li></ul>
          <ul style="text-align: left;"><li><p>Eco-friendly or Green Office, business parks and industrial properties with potential for future enhancement</p></li></ul>
      </div>
    </div>

    <!--SECTION3-->
     <div class="container section3">

      <div class="row pb-5" style="text-align: center;">
          <h4>Investment Properties & Others segment</h4>
      </div>

      <div class="section3-contents px-sm-5 mx-sm-4">
        <div class="containerNav nav-bar">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <button id="btn_construction" class="tablinks nav-link constructionbtn"
                (click)="openCity('construction')">Construction Segment</button>
            </li>
            <li class="nav-item">
              <button id="btn_investment" class="tablinks nav-link active section3btn" (click)="openCity('investment')">Investment Properties
                & Others segment</button>
            </li>
          </ul>
        </div>
      </div>

      <div class="row pb-5">
          <div class="card card-segment2 col-md-3 col-sm-6 col-6 mx-auto">
              <img src="../assets/image/Component/P4-IP1_(DEFAULT).png" class="img-responsive">
          </div>

          <div class="card card-segment2 col-md-3 col-sm-6 col-6 mx-auto">
              <img src="../assets/image/Component/P4-IP2_(DEFAULT).png" class="img-responsive">
          </div>

          <div class="card card-segment2 col-md-3 col-sm-6 col-6 mx-auto">
              <img src="../assets/image/Component/P4-IP3_(DEFAULT).png" class="img-responsive">
          </div>

          <div class="card card-segment2 col-md-3 col-sm-6 col-6 mx-auto">
              <img src="../assets/image/Component/P4-IP4_(DEFAULT).png" class="img-responsive">
          </div>
      </div>
   </div>
</div>

<app-footer></app-footer>