<app-header></app-header>

<div class="section" style="margin-top: 5%;">
    <div class="container mt-5 pt-5">
      <div class="row" style="margin: 50px 0px;">
        <div class="col-md-12">
          <h3  style="text-align: center;">
            <strong>PRIVACY POLICY</strong>
          </h3>
          <p>
            Ingenieur Gudang Berhad and any of its holding, subsidiary or related companies as defined in the Companies Act 2016 (collectively, "DGB Group", "we", "us" or "our") 
            respects your rights with regards to your personal information when you communicate with us.
          </p><p>
              This Privacy Policy("Privacy Policy") explains what personal data we collect about people who visit our website ("Website"), 
            when and why we collect it, how we use it, the conditions under which we may disclose it to others, and how we keep it secure.
           </p><p>
          By using our websites, applications or by otherwise giving us your personal information you are accepting the practices described in this Privacy Policy. 
          If you do not agree to this Privacy Policy, please do not use our websites or applications or give us any of your personal information. We reserve the right to make changes to this Privacy Policy at any time.
          </p>    
          <p class="mbr-text mbr-fonts-style display-7 textjustify " style="text-align: left;">
            <strong>Personal Data</strong>
          </p>

          <ol >
            <p> <li> 
              Personal data processed by us may includebut not limited to: 
              <p> <br>
               a) your name, job title, company name; <br>
               b) age,  gender,  date  of  birth,  national registration  identity  card  number,  passport number; <br>
               c) race, ethnic original, nationality; <br>
               d) contact details including emails, address, phone number; and/or <br>
               e) any other personal data required from you for the purposes set out in paragraph 2. 
            </p>    
        </li></p> <br>

        <li>Purposes of Personal Data <br>

       <p> Collection Dynaciate may use your personal data for the following purposes (“Purposes”): </p>  <br>
      
                a) to communicate the requested information; <br>
                b) to enable us to discharge our contractual obligations; <br>
                c) to provide products and/or services; <br>
                d) to consider applications for employment; <br>
                e) to comply with legal and/or regulatory requirements;and <br>
                f) for any other purpose that is incidental or ancillary or in furtherance to the above purposes. <br>
        </li> <br>

        <p> <li> 
          Disclosure of Personal Data Your personal data may be disclosed to:
          <p> <br>
            a) our parent company, subsidiaries, related or affiliated companies; <br>
            b) any authorized third partywho we engage to process personal data on our behalf;and <br>
            c) our auditors, professional advisers, consultants, professional firms or entities.Not with standing that any such persons may be outside Malaysia, for any of the purposes or any other purposes for which your personal data was to be disclosed at the time of its collection orany other purpose directly related to any of the above purposes or where such disclosure is required or authorised by law or by order of a court.
        </p>    
    </li></p> <br>

            <p> <li> 
              Source of Personal DataThe Personal Data will be collected, processed and used by us are sourced from wholly legitimate and transparent means but is not limited to:-
              <p> <br>
                a) Information provided to us directly by yourself;
                b) any emails or any correspondences that we have received from you requesting for information or making any inquiries;
                c) any forms that you have submitted on our website or any websites contracted by us;d)any messages or comments submitted to us in whatever manner; ande)any  referrals  from  a  person  which  have included  their  verifiable  personal  contact details.
            </p>    
        </li></p> <br>

        <p> <li> 
          Insufficiency of Personal Data
          <p> <br>
            If the Personal Data supplied to us is insufficient or is found to be unsatisfactory, we may not be able to process your personal data to carry out the Purpose(s) as set out above.
        </p>    
        </li></p> <br>

        <p> <li> 
          Links to other websites and services
          <p> <br>
          
            Our websites may contain links to third party websites, and some of our digital services provide you with access to third party services (such as social networks).
          </p>
          <p>
              We  have  no  control  over  how third-party websites  and  services  process  your  personal information.  We  do  not  review  third  party  websites  and  services,  and  we  are  not responsible for such third-party websites and services or their privacy practices. Please read the privacy policies of any third-partywebsites or services that you access from our websites or services.
            </p>
          
        </li></p> <br>

      <p> <li> 
        
        Security<br>
        <p> 
          We shall keep and process your personal data in a secure manner. We endeavour, where practicable,   to   implement   the   appropriate   security   measures   and   procedures   in accordance with the Personal Data Protection Act 2010 and relevant regulations.
        </p>
      </li></p> <br>



        </ol>
      
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>