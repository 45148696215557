import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(
    private route: Router,
    private viewportScroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    const d = new Date();
    let year:string = String(d.getFullYear());
    let y =   (document.getElementById("year") as HTMLSpanElement).innerHTML = year;
  }

  goToTnc(){
    this.route.navigate(['tnc']);
  }

  goToCarriage(){
    //this.route.navigate(['tnc-carriage']);
    window.open('https://lineclearexpress.com/doc/Terms_and_Conditions.pdf', '_blank');
  }

  goToPolicy(){
     this.route.navigate(['policy']);

  }
  goToDisclaimer(){
    this.route.navigate(['disclaimer']);

 }

  goToPrivacyPolicy(){
   // this.route.navigate(['privacy_policy']);
    window.open('https://lineclearexpress.com/doc/privacy_policy.pdf', '_blank');
  }

  goToLanding(){

    this.route.navigate(['']);
  }
  
  goToService(){
    window.location.href = '/#AnchorId';
    // this.route.navigateByUrl('/landing#AnchorID');
    // this.router.navigate(['landing'], { fragment: 'AnchorID' });
  }

   goToAbout(){
    this.route.navigate(['about-us']);
  }

  goToContact(){
    this.route.navigate(['contactus']);
  }

  openRate(){
    window.open('https://lineclearexpress.com/doc/price_rate.pdf', '_blank');
  }

  openLocalRate(){
    window.open('https://lineclearexpress.com/doc/walk_in_rate.pdf', '_blank');
  }

  openLineshield(){
    // window.open('https://lineclearexpress.com/doc/lineshield.pdf', '_blank');
    this.route.navigate(['lineshield']);
  }

  goToFaq(){
    this.route.navigate(['faq']);
  }

  public onClick(elementId: string): void { 

    this.viewportScroller.scrollToAnchor(elementId);
    
    //   window.location.href = '/v2/landing#AnchorId';
  }
  
  public goToFragment(fragment: string): void{

    // let elementId = 'serviceId'
    
    // this.route.navigate( ['/landing#'], {fragment: fragment});
    // this.route.navigate(['/landing#'], { fragment: 'serviceId' });
    // this.route.navigate(['/landing#'+fragment])
    // this.redirectTo('/landing#serviceId');
    // window.location.href = '/v2/landing#' + fragment;
    // window.location.href = '/landing#' + fragment;
    // window.location.href = 'https://lineclearexpress.com/v2/landing#serviceId'
    // window.location.replace("http://someUrl.com");
    // this.viewportScroller.scrollToAnchor(elementId);

    // production
    // this.route.navigateByUrl("/v2/landing#serviceId")

    // staging 
    this.route.navigateByUrl("/#serviceId")
    
  }


}

