import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from "rxjs";
import { PlatformLocation } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    ingeniuerURL: any;
    currBaseURL: any;
    trackerURL:any;
    cmsURL: any;
    token:any;
  
    tokenCMS:any;

  constructor(
      private http: HttpClient,
      private platformLocation: PlatformLocation
  ) {
      this.checkBaseUrl();
  }

  checkBaseUrl() {

      this.currBaseURL = (this.platformLocation as any).location.origin;

      let prodURL = this.currBaseURL.match(/production.ai/g);
      let localURL = this.currBaseURL.match(/localhost/g);

      if (prodURL != null) {
        //   this.ingeniuerURL = "http://staging.ingeniuer.com/my";
        this.ingeniuerURL = "http://localhost:8080/ingeniuer-web-api";

      } else {
         this.ingeniuerURL = "http://localhost:8080/ingeniuer-web-api";
        // this.ingeniuerURL = "http://staging.ingeniuer.com/my";
     
      }
  }


  

    postSendEmail(data){
 
        const httpOptions = {
            headers: new HttpHeaders(
            { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
            })
        }
   
        const url =this.ingeniuerURL + "/enquiry.php";
            console.log(data);
        return this.http.post(url, JSON.stringify(data), httpOptions);
    }

  
}

