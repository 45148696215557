<app-header></app-header>


<div class="section mt-0" style="display: flex; justify-content: center; align-items:center;">
  <div class="layer col-xs-9 col-lg-8">
    <div class="row" style="display: flex; justify-content: center; align-items: flex-start;">
     

      <div class="col-lg-5 col-md-5 col-sm-12 sidebar-info">
        
          <p>INGENIEUR GUDANG BERHAD <br> 
              Registration No. 200601012544 (732294-W)</p>
          <p>Ground Floor, No. 3, Jalan TP 2,
            Taman Perindustrian UEP, Subang Jaya,
            47600 Selangor 
            </p>
          <p>Tel : 603-7866 2303</p>
          <p>Email: <a href="mailto:ir@ingenieur.com.my"><b><u> ir@ingenieur.com.my </u> </b></a></p>
        
        </div>
         
        <div class="col-lg-7 col-md-7 col-sm-12 map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.197914536771!2d101.56308441422686!3d3.0415565977850196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4d293070b0b1%3A0xd21df5b1a41377b4!2s3%2C%20Jalan%20TP%202%2C%20Taman%20Perindustrian%20Uep%2C%2047600%20Subang%20Jaya%2C%20Selangor%2C%20Malaysia!5e0!3m2!1sen!2sus!4v1658887826017!5m2!1sen!2sus" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
     
          </div>
        
        </div>
     
    </div>
  
</div>

<app-footer class="footer"></app-footer>