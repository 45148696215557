<app-header></app-header>

<div class="section">
  <div class="section1">
    <div class="container containerPosition" style="position: relative; top: 23%;">
      <div class="row col-lg-12 col-md-12">
         <h3 style="margin-bottom: 3%;">Join Ingenieur Family</h3>
      </div>
      <div class="row col-lg-8 col-md-7">
         <p style="padding-bottom:2%; ">Our Group consider all our employees to be our most valuable capital asset. We firmly focused on 
            valuing employees and their development within the organization. We offer a series of developmental track on yearly 
            basis to grow our employees. We also acknowledge and award employees' talent, performance and contributions to our Group.</p><br>
         <p style="padding-bottom:2%; ">If you want to explore the exciting opportunities in Ingenieur, this is a great place to begin. 
            You can meet our people, explore various career paths, and learn about our values. If you are interested, kindly email 
            your complete resume (with attached photograph) to <a href="mailto:hr@ingenieur.com.my"><b> <u> hr@ingenieur.com.my </u></b></a>  
         </p><br>
         <p style="margin-bottom: 10%;"> By sending us your resume, you are deemed to have understood and agreed to the terms and conditions as outlined in the Privacy Notice.</p>
      </div>
    </div>
  </div>
</div>

<app-footer class="footer"></app-footer>