import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { HeaderComponent } from './header/header.component';
import { ContactComponent } from './contact/contact.component';
import { TncComponent } from './tnc/tnc.component';
import { TncCarriageComponent } from './tnc-carriage/tnc-carriage.component';
import { PolicyComponent } from './policy/policy.component';
import { PrivacyPolicyComponent } from './privacy_policy/privacy_policy.component';
import { InvestorComponent } from './investor/investor.component';
import { NewsComponent } from './news/news.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { CareerComponent } from './career/career.component';
import { OurTeamComponent } from './our-team/our-team.component';
import { GroupStructureComponent } from './group_structure/group_structure.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { CoreBusinessComponent } from './core-business/core-business.component';

const routes: Routes = [

    // sample later change 
    {
        path: "",
        component: LandingComponent
    },
    {
        path: "landing",
        component: LandingComponent
    },
    {
        path: "header",
        component: HeaderComponent
    },
    {
        path: "contactus",
        component: ContactComponent
    },
    {
        path: "tnc",
        component: TncComponent
    },
    {
        path: "tnc-carriage",
        component: TncCarriageComponent
    },
    {
        path: "policy",
        component: PolicyComponent
    },
    {
        path: "privacy_policy",
        component: PrivacyPolicyComponent
    },
    {
        path: "about-us",
        component: AboutUsComponent
    },
    {
        path: "investor",
        component: InvestorComponent
    },
    {
        path: "news",
        component: NewsComponent
    },
    {
        path: "career",
        component: CareerComponent
    },
    {
        path: "our-team",
        component: OurTeamComponent
    },
    {
        path: "group_structure",
        component: GroupStructureComponent
    },
    {
        path: "disclaimer",
        component: DisclaimerComponent
    },
    {
        path: "core-business",
        component: CoreBusinessComponent
    }


    // { path: "", redirectTo: "/landing", pathMatch: "full" }

    // { path: "**", redirectTo: "/landing" }
    // { path: "**", redirectTo: "/landing", pathMatch: "full" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled' ,
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }



