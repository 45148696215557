import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser'; 


@Component({
  selector: 'app-core-business',
  templateUrl: './core-business.component.html',
  styleUrls: ['./core-business.component.css']
})
export class CoreBusinessComponent implements OnInit {
  constructor(
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Core Business | Ingenieur")
    this.openCity('construction');
  }


  openCity(segment) {
    var i = 0;
    var tabcontent;
    var tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
     document.getElementById(segment).style.display = "block";
     document.getElementById("btn_"+segment).className += " active";   
  }

  

}
